<template>
  <div class="module-view">
    <!-- Modal -->
    <Modal
      :modalType="'delete'"
      :moduleAsset="activeAsset"
      v-if="deleteModal"
      @close-modal="promptDelete"
      @delete="deleteAsset"
    />

    <Loading v-if="!dataAvailable" :type="'section'" />

    <div v-if="dataAvailable">
      <!-- Progress Update -->
      <div class="progress">
        <transition-group name="progress-update">
          <span :key="1" class="status-msg error" v-show="error">
            <CancelIcon class="cancel-icon" /> Error: {{ statusMsg }}
          </span>
          <span :key="2" class="status-msg success" v-show="success">
            <Check class="check-icon" /> Success: {{ statusMsg }}
          </span>
        </transition-group>
        <PorscheButton
          v-if="dataAvailable && !retrievalError"
          @click="enablePreviewMode"
          type="button"
          :class="{ 'porsche-button-inactive': !disabledState }"
        >
          <Arrow class="arrow-icon icon" />
          <span>Preview workbook</span>
        </PorscheButton>
        <PorscheButton :light="true" @click="goToRoute('workbooks')">
          <Arrow class="arrow-icon icon" />
          <span>Exit</span>
        </PorscheButton>
      </div>

      <div class="view-heading">
        <h1 class="view-title">Module Setup</h1>
        <p class="view-summary">
          Create a new module by completing the form field below and saving your module information. You can also add
          assets to this module below. Each added asset must be saved individually.
        </p>
      </div>

      <div v-if="!retrievalError" class="module-content">
        <div class="input">
          <label for="moduleTitle">Module Title</label>
          <input type="text" id="moduleTitle" v-model.lazy="localModule.title" />
        </div>

        <div class="textarea">
          <label for="moduleDescription">Participant Module Description</label>
          <quill-editor
            ref="myQuillEditor"
            class="quill-editors"
            :options="editorOption"
            v-model="localModule.description"
          />
        </div>

        <div class="textarea">
          <label for="moduleDescription">Instructor Module Description</label>
          <quill-editor
            ref="myQuillEditor"
            class="quill-editors"
            :options="editorOption"
            v-model="localModule.instructorDescription"
          />
        </div>

        <div class="checkbox">
          <input type="checkbox" @input="lockedUpdate" v-model="localModule.isLocked" />
          <span>Lock this module</span>
          <Check v-show="localModule.isLocked" class="check-icon" />
        </div>

        <div class="mutltiple-inputs" v-if="localModule.isLocked">
          <div class="input">
            <label for="accessCode">Access Code</label>
            <input type="text" id="accessCode" v-model="localModule.accessCode" />
            <span class="disclaimer">
              You and the instructors are responsible for providing these access codes to workbook participants.
            </span>
          </div>
          <div class="date-time">
            <div class="date-time-options">
              <div class="date-picker">
                <label for="">Date</label>
                <div class="prime-cal">
                  <PrimeCalendar v-model="localModule.date" selectionMode="single" :showButtonBar="true" />
                  <CalendarIcon class="cal-icon" />
                </div>
              </div>
              <div class="date-picker">
                <label for="">Time</label>
                <div class="prime-cal">
                  <PrimeCalendar v-model="localModule.time" :showTime="true" :timeOnly="true" hourFormat="12" />
                  <Clock class="clock-icon" />
                </div>
              </div>
            </div>
            <span class="disclaimer">
              Time shown is in your local time. After this date and time, this module will no longer require an unlock
              code.
            </span>
          </div>
        </div>

        <div class="save-content">
          <PorscheButton type="submit" @click="saveModule">
            <Arrow class="arrow-icon icon" />
            <span v-if="currentModule.id.length > 11">Update module information</span>
            <span v-else>Save module information</span>
          </PorscheButton>
        </div>
        <hr />

        <div class="asset-builder" v-if="currentModule.id.length > 11">
          <h1 v-if="localModuleAssets.length > 0" class="view-title">Module Assets</h1>

          <!-- Assets -->
          <draggable :list="localModuleAssets" :disabled="disableDrag">
            <ModuleAssets
              v-for="(asset, index) in localModuleAssets"
              :key="asset.id"
              :index="index"
              :asset="asset"
              :moduleId="currentModule.id"
              :assetVisible="asset.assetVisible"
              @prompt-delete="promptDelete"
              @createAsset="updateAsset"
              @toggleAssetView="toggleAssetView"
            />
          </draggable>

          <h1 class="view-title">Add Asset</h1>

          <!-- Asset Builder Tags -->
          <div class="asset-builders flex">
            <div
              @dragstart="dragStart"
              draggable="true"
              v-for="asset in assetBuilders"
              :key="asset.id"
              class="asset-tab flex"
            >
              <Survey class="icon" v-if="asset.name === 'survey'" />
              <Quiz class="icon" v-if="asset.name === 'quiz'" />
              <Waiver class="icon" v-if="asset.name === 'waiver'" />
              <span class="asset-name">{{ asset.name }}</span>
            </div>
          </div>

          <!-- Dropzone -->
          <div class="dropzone" v-on:drop="drop" @dragover="allowDrop"></div>
        </div>
      </div>
      <div v-else>
        <h2>Something went wrong, please try again.</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'uid';
import CancelIcon from '@/assets/images/Icons/cancel.svg';
import ApiService from '@/utilities/ApiService';
import { createNamespacedHelpers } from 'vuex';
import ModuleAssets from '@/components/ModuleAssets.vue';
import draggable from 'vuedraggable';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import Loading from '@/components/Loading.vue';
import Clock from '@/assets/images/Icons/clock.svg';
import Check from '../assets/images/Icons/check.svg';
import Survey from '../assets/images/Icons/icon_survey.svg';
import Quiz from '../assets/images/Icons/icon_quiz.svg';
import Waiver from '../assets/images/Icons/icon_waiver.svg';
import PorscheButton from './PorscheButton.vue';
import Modal from './Modal.vue';
import PrimeCalendar from './PrimeCalendar.vue';
import CalendarIcon from '../assets/images/Icons/calendar.svg';

const { mapActions, mapMutations, mapState } = createNamespacedHelpers('workbookBuilder');

export default {
  name: 'module-details',
  components: {
    Check,
    ModuleAssets,
    draggable,
    Survey,
    Quiz,
    Waiver,
    PorscheButton,
    CancelIcon,
    Arrow,
    Loading,
    Modal,
    PrimeCalendar,
    CalendarIcon,
    Clock,
  },
  props: ['currentModule', 'index'],
  data() {
    return {
      dataAvailable: null,
      retrievalError: null,
      deleteModal: null,
      activeAsset: null,
      error: null,
      success: null,
      statusMsg: null,
      localModule: null,
      localModuleAssets: [],
      disableDrag: false,
      editorOption: {
        modules: {
          toolbar: [
            { header: [1, 2, 3, 4, 5, 6, false] },
            'bold',
            'italic',
            { list: 'ordered' },
            { list: 'bullet' },
            'link',
          ],
        },
      },
      assetBuilders: [
        {
          name: 'waiver',
        },
        {
          name: 'survey',
        },
        {
          name: 'quiz',
        },
      ],
      draggedAsset: '',
    };
  },
  async created() {
    window.scrollTo(0, 0);
    await this.getModule();
  },
  computed: {
    ...mapState(['moduleAssets', 'moduleInfo', 'workbook', 'isDirty']),

    disabledState() {
      return this.$route.params.workbookId.length > 11;
    },
  },
  watch: {
    localModule: {
      deep: true,
      handler() {
        if (JSON.stringify(this.moduleInfo) !== JSON.stringify(this.localModule)) {
          this.SET_ISDIRTY();
          return;
        }
        this.RESET_ISDIRTY();
      },
    },

    localModuleAssets() {
      if (JSON.stringify(this.moduleAssets) !== JSON.stringify(this.localModuleAssets)) {
        if (!this.disableDrag) {
          this.updateAssetOrdering();
        }
      }
    },

    moduleAssets() {
      this.localModuleAssets = [...this.moduleAssets];
    },
  },
  methods: {
    ...mapMutations([
      'UPDATE_MODULE',
      'UPDATE_MODULE_TITLE',
      'ADD_MODULE_ASSET',
      'SET_MODULE',
      'SET_MODULE_ASSETS',
      'UPDATE_ASSET_ORDERING',
      'SET_ISDIRTY',
      'RESET_ISDIRTY',
      'REMOVE_MODULE_ASSET',
    ]),
    ...mapActions(['GET_MODULE', 'GET_MODULE_ASSETS']),

    toggleAssetView(index) {
      // if open, close all
      if (this.localModuleAssets[index].assetVisible) {
        this.localModuleAssets.forEach((asset) => {
          asset.assetVisible = false;
        });
        this.disableDrag = false;
        return;
      }
      // not open, close all then open correct asset
      this.localModuleAssets.forEach((asset) => {
        asset.assetVisible = false;
      });
      this.localModuleAssets[index].assetVisible = true;
      this.disableDrag = true;
    },

    HTTPSuccess(msg) {
      this.success = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },

    HTTPError(msg) {
      this.error = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.error = false;
      }, 5000);
    },

    async getModule() {
      try {
        // Get existing module
        if (this.currentModule.id.length > 11) {
          await this.GET_MODULE(this.currentModule.id);
          this.localModule = { ...this.moduleInfo };
          this.localModuleAssets = [...this.moduleAssets];
          this.dataAvailable = true;
          return;
        }

        // New Module
        this.localModule = { ...this.moduleInfo };
      } catch (error) {
        this.retrievalError = true;
        this.HTTPError(error);
        console.warn(error.message);
      }
      this.dataAvailable = true;
    },

    getTime(scheduledTime) {
      const hour = scheduledTime ? new Date(scheduledTime).getUTCHours() : new Date().getUTCHours();
      const getMinutes = scheduledTime ? new Date(scheduledTime).getUTCMinutes() : new Date().getUTCMinutes();
      const minutes = getMinutes < 10 ? `0${getMinutes}` : getMinutes;
      const setSeconds = scheduledTime ? new Date(scheduledTime) : new Date();
      setSeconds.setSeconds(scheduledTime ? setSeconds.getSeconds() + 0 : setSeconds.getSeconds() + 20);
      const getSeconds = setSeconds.getUTCSeconds();
      const seconds = getSeconds < 10 ? `0${getSeconds}` : getSeconds;
      const time = `${hour}:${minutes}:${seconds}`;
      return time;
    },

    async saveModule() {
      // reset error
      this.success = false;
      this.error = false;

      // scroll to top
      window.scrollTo(0, 0);

      // Validate.
      if (this.localModule.title !== '') {
        // Check to see if module is locked.
        if (this.localModule.isLocked) {
          // Validate locked req.
          if (!this.localModule.accessCode && (!this.localModule.time || !this.localModule.date)) {
            // Req not met, show error. leave function
            this.HTTPError('Ensure all fields are completed.');
            return;
          }
          if (this.localModule.accessCode) {
            // Access code must be alphanumeric && 6 chars long
            if (this.localModule.accessCode.length !== 6 || !this.localModule.accessCode.match('^[a-zA-Z0-9]*$')) {
              // Req not met, show error. leave function
              this.HTTPError('access code must be alphanumeric and 6 characters long');
              return;
            }
          }
        }
        try {
          // calculate time, if needed
          let date = null;
          let time = null;

          if (this.localModule.date && this.localModule.time) {
            date = new Date(this.localModule.date).toLocaleDateString('zh-Hans-CN').replaceAll('/', '-');
            time = this.getTime(this.localModule.time);
          }

          // Module exists? Update.
          if (this.currentModule.id.length > 11) {
            await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/${this.localModule.id}`, {
              title: this.localModule.title,
              workbookId: this.workbook.id,
              description: this.localModule.description,
              instructorDescription: this.localModule.instructorDescription,
              isLocked: this.localModule.isLocked,
              accessCode: this.localModule.accessCode ? this.localModule.accessCode : '',
              unlockTime: this.localModule.date && this.localModule.time ? `${date} ${time}` : false,
            });

            this.UPDATE_MODULE({
              content: this.localModule,
              index: this.index,
            });
            this.RESET_ISDIRTY();
            this.HTTPSuccess('Progress Saved');
            return;
          }
          // New Module? Create.
          const create = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/module/`, {
            title: this.localModule.title,
            workbookId: this.workbook.id,
            description: this.localModule.description,
            instructorDescription: this.localModule.instructorDescription,
            isLocked: this.localModule.isLocked,
            accessCode: this.localModule.accessCode ? this.localModule.accessCode : '',
            unlockTime: this.localModule.date && this.localModule.time ? `${date} ${time}` : false,
            order: this.index,
          });

          this.localModule.id = create.data.data.id;

          // Updates ID in Module & Workbook Navigation
          this.UPDATE_MODULE({
            content: this.localModule,
            index: this.index,
          });

          // Updates Local ID stored in Parent (Workbook Content)
          this.$emit('current-module', create.data.data.id);

          this.HTTPSuccess('Progress Saved');
        } catch (err) {
          this.HTTPError(err.message);
        }
        return;
      }
      // Req not met, show error.
      this.HTTPError('Ensure all fields are completed.');
    },

    promptDelete(asset) {
      this.activeAsset = asset;
      this.deleteModal = !this.deleteModal;
    },

    async deleteAsset() {
      // db asset
      if (this.activeAsset.id.length > 11 || this.activeAsset.newId) {
        try {
          // obtain the correct id
          const id = this.activeAsset.id.length > 11 ? this.activeAsset.id : this.activeAsset.newId;

          // check the asset type, call correct delete endpoint
          if (this.activeAsset.module_asset.type === 'waiver') {
            await ApiService.delete(`${process.env.VUE_APP_API_URL}/workbook/module/waiver/${id}`);
          }
          if (this.activeAsset.module_asset.type === 'quiz') {
            await ApiService.delete(`${process.env.VUE_APP_API_URL}/quiz/${id}`);
          }
          if (this.activeAsset.module_asset.type === 'survey') {
            await ApiService.delete(`${process.env.VUE_APP_API_URL}/survey/${id}`);
          }

          // removes asset from state
          this.REMOVE_MODULE_ASSET(this.activeAsset.id);

          // close modal & send patch with new assets
          this.$nextTick(() => {
            this.deleteModal = false;
          });
        } catch (error) {
          console.warn(error.message);
          this.deleteModal = false;
        }
        return;
      }

      // removes asset from state
      this.REMOVE_MODULE_ASSET(this.activeAsset.id);

      // close modal & re-enabled drag
      this.$nextTick(() => {
        this.deleteModal = false;
        this.disableDrag = false;
      });
    },

    lockedUpdate() {
      if (this.localModule.isLocked) {
        this.localModule.accessCode = null;
        this.localModule.date = null;
        this.localModule.time = null;
      }
    },

    async updateAssetOrdering() {
      const newOrder = [];
      this.localModuleAssets.forEach((item, index) => {
        newOrder.push({
          assetId: item.newId ? item.newId : item.id,
          order: index,
          type: item.module_asset.type,
        });
      });
      await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/order-assets/${this.currentModule.id}`, {
        moduleAssets: newOrder,
      });
    },

    goToRoute(route, param, paramValue) {
      this.$router.push({ name: route, params: { param: paramValue } });
    },

    drop() {
      this.addAssetToPage(this.draggedAsset);
    },

    addAssetToPage(asset) {
      const id = uid();

      // locks assets from being reordered
      this.disableDrag = true;

      if (asset === 'Waiver') {
        this.localModuleAssets.push({
          id,
          newId: null,
          title: '',
          file: false,
          fileName: null,
          fileUrl: null,
          url: null,
          isSignatureRequired: false,
          assetVisible: false,
          module_asset: {
            type: asset.toLowerCase(),
            order: null,
          },
        });
        return;
      }
      if (asset === 'Quiz') {
        this.localModuleAssets.push({
          quiz: {
            title: '',
            id,
            questions: [],
          },
          id,
          newId: null,
          passPercentage: null,
          assetVisible: false,
          module_asset: {
            type: asset.toLowerCase(),
            order: null,
          },
        });
        return;
      }
      this.localModuleAssets.push({
        survey: {
          title: '',
          id,
          questions: [],
        },
        id,
        newId: null,
        assetVisible: false,
        module_asset: {
          type: asset.toLowerCase(),
          order: null,
        },
      });
    },

    allowDrop(e) {
      e.preventDefault();
    },

    dragStart(e) {
      this.draggedAsset = e.target.innerText;
    },

    updateAsset(newAsset) {
      this.disableDrag = false;
      this.localModuleAssets[newAsset.index].newId = newAsset.content.newId;
    },

    enablePreviewMode() {
      this.$router.push({
        name: 'workbook view',
        params: { 'workbook-id': this.workbook.id },
        query: { preview: 'true' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.module-view {
  position: relative;
  width: 100%;

  .save-content {
    margin-top: 24px;
  }

  .module-content {
    .mutltiple-inputs {
      gap: 60px;

      .input {
        margin-bottom: 0;
      }

      .date-time {
        display: flex;
        flex-direction: column;
        .date-time-options {
          display: flex;
          gap: 16px;

          .date-picker {
            margin-bottom: 0;
          }
        }
      }
    }

    .asset-builder {
      .view-title {
        margin-bottom: 24px;
      }
      .asset-builders {
        .asset-name {
          text-transform: capitalize;
        }
      }
    }
  }
}
</style>
